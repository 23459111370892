import axios from 'axios';
export default axios.create({
  //baseURL: 'http://localhost:1337/api/v2',
  baseURL: process.env.VUE_APP_ENDPOINT,
  crossdomain: true,
  //timeout: 1000,
  headers: {
    'Content-type': 'application/json',
    Authorization: 'Bearer ' + process.env.VUE_APP_TOKEN_API
  }
});
