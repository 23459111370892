<template>
  <v-responsive
    min-height="94vh"
    class="d-flex align-center mt-n16 image-background"
    :style="`background-image: url('${background[2]}')`"
  >
    <v-container>
      <v-row class="d-flex justify-center">
        <v-col col xl="4" md="6" sm="12" class="box-10 call-action-glass py-6 px-8 elevation-4">
          <h1 class="display-2 text-center">Registrate</h1>
          <hr class="red">

          <v-alert
            v-if="authResponse.error"
            border="top"
            color="red darken-2"
            type="error"
            elevation="2"
          >
            <span class="grow strong">El correo ya esta registrado</span> 
          </v-alert>

          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >

            <v-text-field
              label="Correo electrónico"
              v-model="auth.email"
              :rules="rules.email"
              prepend-icon="mdi-at"
              filled
            ></v-text-field>

            <v-text-field
              filled
              v-model="auth.password"
              :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
              prepend-icon="mdi-form-textbox-password"
              :rules="rules.password"
              :type="showpass ? 'text' : 'password'"
              label="Contraseña"
              hint="Coloca al menos 8 caracteres"
              counter
              @click:append="showpass = !showpass"
            ></v-text-field>

            <v-text-field
              filled
              :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
              prepend-icon="mdi-form-textbox-password"
              :rules="passwordConfirm"
              :type="showpass ? 'text' : 'password'"
              label="Confirma tu Contraseña"
              hint="Coloca al menos 8 caracteres" 
              counter
              @click:append="showpass = !showpass"
            ></v-text-field>

            <div class="my-2 dorado">
              <v-btn
                :disabled="!valid"
                x-large
                color="rojo"
                dark
                class="float-right"
                @click="authRegister"
              >
                Registrarme
                <v-icon dark>
                  mdi-account-arrow-right
                </v-icon>
              </v-btn>
            </div>

          </v-form>


        </v-col>
      </v-row>
    </v-container>
  </v-responsive>
</template>
<script>

import AuthService from "@/services/auth.service.js";
import UserService from '@/services/user.service';

export default {
  name: 'RegisterComponent',
  
  components: {
    
  },

  data: () => ({
    valid: true,
    showpass: false,
    //errors: {},
    //loginError: '',
    authResponse:[],
    passwordConfirmOk: '',
    UserOrNameExist: false,
    
    auth:{
      username: '',
      email: '',
      password: '',
    },

    rmeSeed: {
      "id": 1,
      "name": "Consulmex",
    },

    rules: {
      username: [
        value => !!value || 'El nombre de usuario es requerido',
      ],
      email: [
        value => !!value || 'El correo electrónico requerido',
        value => /.+@.+/.test(value) || 'Con es un correo electrónico válido',
      ],
      password: [
        value => !!value || 'La Contraseña es obligatoria',
        value => value.length >= 8 || 'Minimo 8 caracteres',
      ],
    },

    path: {
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },

    
    background: [
        '/assets/images/2.jpg',
        '/assets/images/3.jpg',
        '/assets/images/4.jpg'
      ],

  }),

  mounted(){

  },

  computed: {
    
    passwordConfirm() {
      return [
        (v) => (v === this.auth.password) || 'Las contraseñas no coinciden'
      ];
    },

  },

  methods:{


    authRegister(){

      this.$refs.form.validate();
      if(this.$refs.form.validate() === true){

        var data = {
          username: this.auth.email,
          email: this.auth.email,
          password: this.auth.password
        };
        AuthService.register(data).then(
          response => {
            var authResponse = response.data;
            //console.log("🚀 ~ file: RegisterView.vue:173 ~ authRegister ~ authResponse:", authResponse.id)
            //this.adduserProfile(authResponse.id)
            this.$router.push('/auth/login')
          },
          error => {
            this.authResponse =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        )
      }

    },


    adduserProfile(userId){
      var data = {
        user: userId,
        receiveNews: false,
        biography: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        representationId: 200,
        avatar: 1,
        rme: this.rmeSeed
      };
      UserService.createUserProfile({data:data})
        .then(response => {
          this.profile = response
          this.$router.push('/auth/login')
        })
        .catch(e => {
          console.log(e);
        });
    },

  },

}
</script>