<template>
  <section>
    <v-container>
      <v-row>
        <v-col cols md="12">
          <h2 class="display-1 mt-4">
            <span class="font-weight-regular">Top de votos:</span>
            {{
              currentParams.category === "rme"
                ? "Representación"
                : "Comunidad Mexicana"
            }}

            ({{ countDown }})
          </h2>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-center">
          <v-btn-toggle v-model="filters.category" mandatory class="mx-4">
            <v-btn href="/votacion/topten/?category=community">
              <v-icon>mdi-filter</v-icon>
              Comunidad Mexicana
            </v-btn>
            <v-btn href="/votacion/topten/?category=rme">
              <v-icon>mdi-filter</v-icon>
              Representación
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <table class="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th
                  @click="sort('altar_challenge_votes')"
                  v-bind:class="[
                    sortBy === 'altar_challenge_votes' ? sortDirection : '',
                  ]"
                >
                  Votos
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in results">
                <td>{{ item.id }}</td>
                <td>
                  <span v-if="item.answer">
                    {{
                      item.answer.rme
                        ? item.answer.rme
                        : item.answer.nombreCompleto
                    }}
                  </span>
                </td>
                <td class="strong">{{ item.altar_challenge_votes.length }}</td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <pre>{{ results }}</pre>
        </v-col>
        <v-col style="display: none">
          {{ sortedProducts }}
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import wsVotes from "@/services/votes";

export default {
  name: "VotacionTopTenComponent",

  components: {},

  data: () => ({
    countDown: 120,
    filters: {
      category: "community",
      range: "top10",
    },
    sortBy: "altar_challenge_votes",
    sortDirection: "desc",
    //sortedProducts: {},
    results: {},
    path: {
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },
  }),

  computed: {
    currentParams() {
      let category = this.$route.query;
      return category;
    },

    sortedProducts: function () {
      return this.results.sort((p1, p2) => {
        let modifier = 1;
        if (this.sortDirection === "desc") modifier = -1;
        if (p1[this.sortBy] < p2[this.sortBy]) return -1 * modifier;
        if (p1[this.sortBy] > p2[this.sortBy]) return 1 * modifier;
        return 0;
      });
    },
  },

  mounted() {
    this.loadAllResults(
      "dab20a7c-77ce-451b-b4e7-88b58d248e17",
      this.currentParams.range === "top10" ? 10 : 300,
      this.currentParams.category === "rme" ? true : false
    );

    //this.$route.query;
  },

  methods: {
    sort: function (s) {
      if (s === this.sortBy) {
        this.sortDirection = this.sortDirection === "desc" ? "asc" : "desc";
      }
      this.sortBy = s;
    },

    loadAllResults(uuid, pageSize, isRme) {
      wsVotes.getAllAltarChallenge(uuid, pageSize, isRme).then(
        (response) => {
          this.results = response.data.data;
        },
        (error) => {
          this.results =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
          //this.$router.go();
        }, 1000);
      } else {
        this.$router.go();
        //this.sortedProducts;
        //this.countDown = 15;
        //this.countDownTimer();
      }
    },
  },

  created() {
    this.countDownTimer();
  },
};
</script>

<style>
.asc:after {
  content: "\25B2";
}

.desc:after {
  content: "\25BC";
}
</style>
