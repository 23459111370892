<template>
  <section>
    <ResponsiveComponent
      cfgImage="https://api-vinculacion.k0s.ime.red/uploads/reto_de_altares_9834d72f5f.jpg"
      cfgHeight="33vh"
    ></ResponsiveComponent>

    <v-container>
      <v-row v-if="currentUser">
        <v-col cols md="12">
          <h2 class="display-1 mt-4">
            Hola
            <span class="blue-grey--text text--darken-2">
              {{ currentUser.user.email }}
            </span>
          </h2>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center mt-6">
        <v-col cols md="3" class="pa-6 text-center">
          <div class="rounded blue-grey lighten-5 text-h5 pa-6">
            <a
              class="blue-grey--text text--darken-4 text-decoration-none"
              href="/votacion/RetoDeAltares2023/representacion"
            >
              <v-icon
                style="font-size: 4rem"
                class="blue-grey--text text--darken-2"
                >mdi-thumb-up</v-icon
              ><br />
              Vota por una Representación
            </a>
          </div>
        </v-col>
        <v-col cols md="3" class="pa-6 text-center">
          <div class="rounded blue-grey lighten-5 text-h5 pa-6">
            <a
              class="blue-grey--text text--darken-4 text-decoration-none"
              href="/votacion/RetoDeAltares2023/comunidad"
            >
              <v-icon
                style="font-size: 4rem"
                class="blue-grey--text text--darken-2"
                >mdi-thumb-up</v-icon
              ><br />
              Vota por una Comunidad Méxicana
            </a>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="!currentUser" class="d-flex justify-center pb-14">
        <v-col cols md="12" class="text-center">
          <p class="lead mb-0">Para votar inicia sesión o registrate</p>
        </v-col>
        <v-col cols md="3" class="text-right mt-0 pb-14">
          <v-btn
            dark
            large
            rounded
            color="verde"
            class="ma-1"
            href="/auth/register"
            >Registrate</v-btn
          >
        </v-col>
        <v-col cols md="3" class="text-left pb-14">
          <v-btn dark large rounded color="rojo" class="ma-1" href="/auth/login"
            >Inicia sesión</v-btn
          >
        </v-col>
      </v-row>

      <v-row>
        <pre></pre>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import ResponsiveComponent from "@/components/global/_Responsive";

export default {
  name: "VotacionComponent",

  components: {
    ResponsiveComponent,
  },

  data: () => ({
    path: {
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },
  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },

  mounted() {
    document.title = "Reto de Altares 2023 | Vinculacion | IME";
  },

  methods: {
    whoIsMyIp() {
      fetch("https://api.ipify.org?format=json")
        .then((response) => response.json())
        .then((response) => {
          this.clientIp = response.ip;
          this.searchVoterIp(this.clientIp);
        });
    },
  },
};
</script>
