<template>
  <v-container>
    <v-row class="">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Resultados
        </h1>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols md="4" v-for="(item,i) in surveys" :key="i">
        <v-card
          class="mx-auto"
          width="100%"
        >
          <v-img
            class="white--text align-end"
            height="200px"
            :src="`https://api-vinculacion.k0s.ime.red${item.attributes.cover.data.attributes.url}`"
          >
            
          </v-img>

          <v-card-title>{{ item.attributes.title }}</v-card-title>

          <v-card-text class="text--primary">
            <div>{{ item.attributes.description }}</div>
          </v-card-text>

          <v-card-actions>
            <v-btn color="verde" dark test :to="`/form/${item.attributes.name}`">
              Enviar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


</v-container>
</template>
<script>
import wsSurveys from "@/services/surveys";


export default {
  name: 'SurveyList',
  components: {
  },

  data() {
    return {
      surveys: [],
    }
  },

  mounted() {
    this.loadSurveys()
  },

  methods: {

    loadSurveys() {
      wsSurveys
        .getAll()
          .then((response) => {
            this.surveys = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
      },

  },


}
</script>