import http from '@/services/http-common';

class wsAuthentication{

  authEmailConfirmation(token) {
    return http.get(`/auth/email-confirmation/?confirmation=${token}`);
  }

  authForgotPassword(data) {
    return http.post("/auth/forgot-password", data);
  }

  authResetPassword(data) {
    return http.post("/auth/reset-password", data);
  }

  authChangePassword(data) {
    return http.post("/auth/change-password", data);
  }

  authSendEmailConfirmation(data) {
    return http.post("/auth/send-email-confirmation", data);
  }

}

export default new wsAuthentication();