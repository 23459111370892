<template>
  <v-app>
    <v-app-bar app color="rojo" dark>
      <div class="d-flex align-center">
        <v-img
          alt="IME"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://ime.gob.mx/images/ime-mx.png"
          width="100"
        />
        <h1 class="ml-6 text-h5 white--text">Reto de Altares 2023</h1>
      </div>
      <v-spacer></v-spacer>

      <div
        v-if="
          currentController === 'VotacionRetoAltares2023' ||
          currentController === 'VotoComunidad' ||
          currentController === 'VotoRme'
        "
      >
        <v-btn text class="mx-1" href="/votacion/RetoDeAltares2023">
          <v-icon left>mdi-home</v-icon>
          Inicio
        </v-btn>
        <v-btn
          text
          class="mx-1"
          href="/votacion/RetoDeAltares2023/representacion"
        >
          <v-icon left>mdi-home</v-icon>
          Representación
        </v-btn>
        <v-btn text class="mx-1" href="/votacion/RetoDeAltares2023/comunidad">
          <v-icon left>mdi-home</v-icon>
          Comunidad
        </v-btn>
      </div>

      <v-btn v-else text to="/">
        <v-icon left>mdi-home</v-icon>
        Inicio
      </v-btn>

      <v-btn
        text
        href="https://sre.gob.mx/index.php/avisos-de-privacidad-convocatorias"
        target="_blank"
      >
        <v-icon left>mdi-shield-lock</v-icon>
        Privacidad
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <Footer></Footer>
  </v-app>
</template>

<script>
import Footer from "@/components/global/Footer.vue";

export default {
  name: "App",

  components: {
    //GlobalPublicMenuComponent,
    Footer,
  },

  data: () => ({
    //
  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    currentController() {
      let page = this.$route.name;
      console.log("🚀 ~ file: App.vue:81 ~ currentController ~ page:", page);
      return page;
    },
  },

  mounted() {
    document.title = " Vinculacion y Cultura | IME";
  },
};
</script>
