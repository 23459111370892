<template>
  <section>
    <ResponsiveComponent
      cfgImage="https://api-vinculacion.k0s.ime.red/uploads/reto_de_altares_9834d72f5f.jpg"
      cfgHeight="33vh"
    ></ResponsiveComponent>

    <v-container>
      <v-row v-if="currentUser">
        <v-col cols md="12">
          <h2 class="display-1 mt-4">
            Hola
            <span class="rojo-text">
              {{ currentUser.user.email }}
            </span>
          </h2>
          <v-btn
            href="/votacion/RetoDeAltares2023/comunidad"
            dark
            large
            color="dorado-obscuro"
            class="float-right mr-4"
          >
            Vota por una Comunidad
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-6">
        <v-col
          cols
          md="4"
          sm="6"
          v-for="(item, i) in results.data"
          :key="i"
          class="pa-7"
        >
          <div class="grey- lighten--3 pt-3 elevation-6 rounded">
            <h3 class="mb-3 text-center" :id="`challenge-${item.id}`">
              {{
                item.answer.rme ? item.answer.rme : item.answer.nombreCompleto
              }}
            </h3>

            <div v-if="item.answer.fotografiaTerminado">
              <v-img
                v-if="item.answer.fotografiaTerminado[0].type === 'image/jpeg'"
                :lazy-src="item.answer.fotografiaTerminado[0].content"
                :src="item.answer.fotografiaTerminado[0].content"
                aspect-ratio="1.7"
              ></v-img>
              <small v-else>
                {{ item.answer.fotografiaTerminado[0].content }}
              </small>
            </div>

            <div class="chip text-center mt-2">
              <v-chip
                dark
                small
                class="ma-1 text-capitalize"
                color="dorado-obscuro"
              >
                <span v-if="item.answer.ciudad">
                  {{ item.answer.ciudad }},&nbsp;
                </span>
                {{ item.answer.pais }}
              </v-chip>

              <v-chip
                dark
                small
                class="ma-1 text-capitalize"
                color="dorado-obscuro"
              >
                {{ item.publishedAt | moment("dddd, D MMMM YYYY") }}
              </v-chip>
              <!--v-chip v-if="currentUser" small color="primary">
                {{ clientIp }}
              </v-chip-->
            </div>
            <div class="pa-3 votes text-center" v-if="item.id != 3465">
              <div>
                <v-btn
                  dark
                  large
                  rounded
                  color="red lighten-3"
                  class="ma-1 elevation-0"
                >
                  <v-icon left>mdi-thumb-up</v-icon>
                  Votos
                  <v-chip
                    small
                    light
                    :ripple="false"
                    class="ml-2 pa-2 grey lighten-2"
                  >
                    {{ item.altar_challenge_votes.length }}
                  </v-chip>
                </v-btn>
                <v-btn
                  fab
                  dark
                  small
                  color="light-green darken-2"
                  class="ma-1"
                  :href="item.answer.fotografiaTerminado[0].content"
                  target="_blank"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </div>
            </div>
            <div v-else class="pa-3 votes text-center">
              <v-btn
                fab
                dark
                small
                color="light-green darken-2"
                class="ma-1"
                :href="item.answer.fotografiaTerminado[0].content"
                target="_blank"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <pre></pre>
      </v-row>

      <v-row>
        <v-col>
          <PaginationComponent
            v-if="results.meta"
            :page="results.meta.pagination.page"
            :page-size="results.meta.pagination.pageSize"
            :page-count="results.meta.pagination.pageCount"
            :total="results.meta.pagination.total"
          ></PaginationComponent>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PaginationComponent from "@/components/global/_Pagination.vue";
import ResponsiveComponent from "@/components/global/_Responsive";
import wsVotes from "@/services/votes";
import pdf from "vue-pdf";

export default {
  name: "VotoRmeComponent",

  components: {
    pdf,
    ResponsiveComponent,
    PaginationComponent,
  },

  data: () => ({
    voteUpUser: {},
    isVoter: "",
    voterIpClient: "",
    clientIp: "",
    addVote: false,
    results: {
      data: {},
      meta: {
        pagination: {
          page: 1,
        },
      },
    },
    path: {
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },
  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    currentPage() {
      let page = this.$route.query.page;
      return page;
    },
  },

  mounted() {
    document.title = "Reto de Altares 2023 | Vinculacion | IME";

    if (this.currentUser) {
      //this.searchVoteByUser(this.currentUser.user.id);
      //this.searchVoterIp(this.clientIp);
      this.whoIsMyIp();
      //this.searchAltar();
    }

    this.loadAllResults(
      "dab20a7c-77ce-451b-b4e7-88b58d248e17",
      18,
      this.currentPage ? this.currentPage : 1,
      true
    );
  },

  methods: {
    loadAllResults(uuid, pageSize, page, isRme) {
      wsVotes.getResultsAltarChallengeByType(uuid, pageSize, page, isRme).then(
        (response) => {
          this.results = response.data;
        },
        (error) => {
          this.results =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    voteUp(altarId) {
      var data = {
        voteUp: true,
        voter: this.currentUser.user.id,
        altar_challenge: altarId,
        ip_client: this.clientIp,
      };
      wsVotes.getVoteUp({ data: data }).then(
        (response) => {
          this.voteUpUser = response.data.data;

          if (this.voteUpUser) {
            this.$router.go();
          }
        },
        (error) => {
          this.voteUpUser =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    searchVoteByUser(voterId, isRme) {
      wsVotes.searchVote(voterId, isRme).then(
        (response) => {
          this.isVoter = response.data.data.length;
        },
        (error) => {
          this.isVoter =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    searchVoterIp(voterIp, isRme) {
      wsVotes.searchVoterIp(voterIp, isRme).then(
        (response) => {
          this.voterIpClient = response.data.data.length;
          console.log(
            "🚀 ~ file: VotoRme.vue:358 ~ searchVoterIp ~ this.voterIpClient:",
            this.voterIpClient
          );
        },
        (error) => {
          this.voterIpClient =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    searchAltar() {
      wsVotes.searchByAltar().then(
        (response) => {
          this.altar = response.data.data;
          console.log(
            "🚀 ~ file: VotoRme.vue:372 ~ searchAltar ~ this.altar:",
            this.altar
          );
        },
        (error) => {
          this.altar =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    whoIsMyIp() {
      fetch("https://api.ipify.org?format=json")
        .then((response) => response.json())
        .then((response) => {
          this.clientIp = response.ip;
          this.searchVoterIp(this.clientIp, true);
          this.searchVoteByUser(this.currentUser.user.id, true);
        });
    },
  },
};
</script>
