import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_ENDPOINT;

class UserService {

  /*
   * Mi Perfil
   */
  loadUserMe() {
    return axios.get(API_URL + '/users/me', { headers: authHeader() });
  }

  updateMyProfile(id, data) {
    return axios.put(API_URL + `/profiles/${id}`, data, { headers: authHeader() });
  }


}

export default new UserService();
