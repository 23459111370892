<template>
  <section>
    <v-container>
      <v-row>
        <v-col cols md="12">
          <h2 class="display-1 mt-4">
            <span class="font-weight-regular">AltarID:</span>
            <strong>{{ currentParams.id }}</strong>
            ({{ countDown }})
          </h2>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <table class="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>IP</th>
                <th>USER/ID</th>
                <th>FECHA VOTO</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="item in results.data">
                <td>{{ item.id }}</td>
                <td>
                  <span>
                    {{ item.ip_client }}
                  </span>
                </td>
                <td class="strong">
                  {{ item.voter.email }}
                  ({{ item.voter.id }})
                </td>
                <td>{{ item.publishedAt }}</td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <pre>{{}}</pre>
        </v-col>
        <v-col style="display: none"> {{}} </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import wsVotes from "@/services/votes";

export default {
  name: "VotacionAltarComponent",
  components: {},

  data: () => ({
    countDown: 120,
    filters: {
      category: "community",
      range: "top10",
    },
    sortBy: "altar_challenge_votes",
    sortDirection: "desc",
    //sortedProducts: {},
    results: {},
    path: {
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },
  }),

  computed: {
    currentParams() {
      let params = this.$route.params;
      return params;
    },
    /*
    sortedProducts: function () {
      return this.results.sort((p1, p2) => {
        let modifier = 1;
        if (this.sortDirection === "desc") modifier = -1;
        if (p1[this.sortBy] < p2[this.sortBy]) return -1 * modifier;
        if (p1[this.sortBy] > p2[this.sortBy]) return 1 * modifier;
        return 0;
      });
    },
    */
  },

  mounted() {
    this.loadAllResults(this.currentParams.id, 300, 2);
    //this.$route.query;
  },

  methods: {
    sort: function (s) {
      if (s === this.sortBy) {
        this.sortDirection = this.sortDirection === "desc" ? "asc" : "desc";
      }
      this.sortBy = s;
    },

    loadAllResults(id, pageSize, page) {
      wsVotes.getAllAltarVotes(id, pageSize, page).then(
        (response) => {
          this.results = response.data;
        },
        (error) => {
          this.results =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
          //this.$router.go();
        }, 1000);
      } else {
        this.$router.go();
        //this.sortedProducts;
        //this.countDown = 15;
        //this.countDownTimer();
      }
    },
  },

  created() {
    this.countDownTimer();
  },
};
</script>

<style>
.asc:after {
  content: "\25B2";
}

.desc:after {
  content: "\25BC";
}
</style>
