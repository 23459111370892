import axios from 'axios';

const API_URL = process.env.VUE_APP_ENDPOINT;

class AuthService {
  
  login(user) {
    return axios
      .post(API_URL + '/auth/local', {
        identifier: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.jwt) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(API_URL + '/auth/local/register', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }

}

export default new AuthService();
