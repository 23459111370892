<template>
  <v-responsive
    :style="`background-image:url(${cfgImage})`"
    :aspect-ratio="16/9"
    :height="cfgHeight"
    class="grey lighten-2 elevation-15 background">
    <v-card-text v-if="cfgTitle">
      <h1 class="text-uppercase strong">{{ cfgTitle }}</h1>
    </v-card-text>
  </v-responsive>
</template>
<script>

export default {
  name: 'ResponsiveComponent',
	props: {
    cfgImage: {
			type: String,
			default: '/'
		},
    cfgHeight: {
			type: String,
			default: '50vh'
		},
    cfgTitle: {
			type: String,
			default: ''
		},
    cfgTarget: {
			type: String,
			default: '_self'
		}
	}
}
</script>