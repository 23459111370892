<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <v-col col xl="4" md="6" sm="12">
        <h1 class="display-2">Verificar correo</h1>
        <hr class="red">
        <p class="lead mt-5">
          Ingresa el codigo que te fue enviado vía Correo electrónico
        </p>

        <v-alert
          v-if="loginError.name === 'ValidationError'"
          color="red darken-1"
          type="error"
        >
          <v-row align="center">
            <v-col class="grow strong">
              El usuario y/o la contraseña no coinciden
            </v-col>
          </v-row>
        </v-alert>

        <v-alert
          v-if="UserOrNameExist === true"
          border="top"
          color="red darken-2"
          type="error"
          elevation="2"
        >
          <span class="grow strong">El correo ya esta registrado</span> 
        </v-alert>

        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >

          <v-text-field
            label="Código de verificación"
            :value="this.$route.query.token"
            v-model="auth.tokenCode"
            :rules="rules.tokenCode"
            prepend-icon="mdi-form-textbox-password"
            filled
          ></v-text-field>


          <div class="my-2 dorado">
            <v-btn
              :disabled="!valid"
              x-large
              color="rojo"
              dark
              class="float-right"
              @click="emailConfirmationToken"
            >
              Verificar
              <v-icon dark class="ml-1">
                mdi-cellphone-key
              </v-icon>
            </v-btn>
          </div>

        </v-form>

        <pre>{{loginError}}</pre>

      </v-col>
    </v-row>
	</v-container>
</template>
<script>
import axios from 'axios';
import wsAuth from "@/services/authentication.js";

export default {
  name: 'EmailConfirmationComponent',
  
  components: {
    
  },

  data: () => ({
    valid: true,
    showpass: false,
    errors: {},
    loginError: '',
    UserOrNameExist: false,
    
    auth:{
      tokenCode: '',
    },

    rules: {
      tokenCode: [
        value => !!value || 'El nombre de usuario es requerido',
      ],
    },

    path: {
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },

  }),

  mounted(){
    this.touchToken(this.$route.query.token);
    console.log("🚀 ~ file: EmailConfirmation.vue ~ line 111 ~ mounted ~ this.$route.query.token", this.$route.query.token)
  },

  computed: {
    
    passwordConfirm() {
      return [
        (v) => (v === this.auth.password) || 'Las contraseñas no coinciden'
      ];
    },

  },

  methods:{

    emailConfirmationToken(){
      this.emailToken = this.auth
      console.log("🚀 ~ file: EmailConfirmation.vue ~ line 110 ~ mounted ~ this.emailToken", this.emailToken)
    },

    touchToken(token) {
      wsAuth.authEmailConfirmation(token)
        .then(response => {
          this.tokenTouch = response.data.data;
        })
        .catch(e => {
          this.errors = e.response.data.error
          console.log("🚀 ~ file: EmailConfirmation.vue ~ line 137 ~ touchToken ~ this.errors", this.errors)
          //console.log(e);
        });
    },


    authRegister() {
      
      this.$refs.form.validate();
      if(this.$refs.form.validate() === true){

        var data = {
          username: this.auth.email,
          email: this.auth.email,
          password: this.auth.password
        };

        axios.post( this.path.endpoint +"/auth/local/register", data)
        .then((response)=>{
          console.log("🚀 ~ file: Login.vue ~ line 140 ~ .then ~ response.data", response.data)

        }).catch((e)=>{
          this.errors = e.response
          //console.log("🚀 ~ file: Register.vue ~ line 172 ~ .then ~ this.errors", this.errors.data.error.message)
          if(this.errors.data.error.message === 'Email or Username are already taken'){
            this.UserOrNameExist = true
          }
        })

      }

    },



  },

}
</script>