import http from "@/services/auth-common";
import authHeader from "@/services/auth-header";

let token = process.env.VUE_APP_TOKEN_API;

class wsVotes {
  getAllVotes() {
    return http.get("/altar-challenge-votes/");
  }

  getAllAltarVotes(id, pageSize, page) {
    return http.get(
      `/altar-challenge-votes/?filters[altar_challenge][id][$eq]=${id}&sort=ip_client:asc&populate[altar_challenge][fields][0]=id&populate[voter][fields][0]=email&pagination[pageSize]=${pageSize}&pagination[page]=${page}`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
  }

  getAllAltarChallenge(uuid, pageSize, isRme) {
    return http.get(
      `/survey-results/?filters[survey][uuid][$eq]=${uuid}&filters[rmeName][$notNull]=${
        isRme ? isRme : false
      }&populate[altar_challenge_votes][fields][1]=*&sort=altar_challenge_votes:desc&pagination[pageSize]=${pageSize}`,
      { headers: { Authorization: "Bearer " + token } }
    );
  }

  getResultsAltarChallenge(uuid, pageSize, page) {
    return http.get(
      `/survey-results/?filters[survey][uuid][$eq]=${uuid}&populate[altar_challenge_votes][fields][0]=voteUp&sort=fullName:asc&pagination[pageSize]=${pageSize}&pagination[page]=${page}`,
      { headers: { Authorization: "Bearer " + token } }
    );
  }

  getResultsAltarChallengeByType(uuid, pageSize, page, isRme) {
    return http.get(
      `/survey-results/?filters[survey][uuid][$eq]=${uuid}&filters[rmeName][$notNull]=${
        isRme ? isRme : false
      }&populate[altar_challenge_votes][fields][0]=voteUp&sort=fullName:asc&pagination[pageSize]=${pageSize}&pagination[page]=${page}`,
      { headers: { Authorization: "Bearer " + token } }
    );
  }

  searchVote(voterId, isRme) {
    return http.get(
      `/altar-challenge-votes/?filters[voter][id][$eq]=${voterId}&filters[altar_challenge][rmeName][$notNull]=${
        isRme ? isRme : false
      }`,
      { headers: authHeader() }
    );
  }

  searchVoteAndIp(voterId, voterIp, isRme) {
    return http.get(
      `/altar-challenge-votes/?filters[voter][id][$eq]=${voterId}&filters[ip_client][$eq]=${voterIp}&filters[altar_challenge][rmeName][$notNull]=${
        isRme ? isRme : false
      }`,
      { headers: authHeader() }
    );
  }

  searchVoterIp(voterIp) {
    return http.get(
      `/altar-challenge-votes/?filters[ip_client][$eq]=${voterIp}`, //&filters[altar_challenge][rmeName][$notNull]=${isRme ? isRme : false}
      { headers: authHeader() } //200.33.160.194
    );
  }

  getVoteUp(data) {
    return http.post("/altar-challenge-votes", data, { headers: authHeader() });
  }

  getVoteDown(data) {
    return http.post("/altar-challenge-votes", data, { headers: authHeader() });
  }
}

export default new wsVotes();
