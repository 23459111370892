import http from "@/services/http-common";

class wsSurveys {
  getAll() {
    return http.get(
      "/surveys/?fields[0]=uuid&fields[1]=name&fields[2]=title&fields[3]=description&fields[4]=startDate&fields[5]=endDate&populate[cover][fields][6]=name&populate[cover][fields][7]=url"
    ); //&populate=*
  }

  get(name) {
    return http.get(
      `/slugify/slugs/survey/${name}?populate[cover][fields][0]=name,url&populate[imagePdf][fields][0]=name,url`
    );
  }

  create(data) {
    return http.post("/surveys", data);
  }

  update(id, data) {
    return http.put(`/surveys/${id}`, data);
  }

  delete(id) {
    return http.delete(`/surveys/${id}`);
  }

  deleteAll() {
    return http.delete(`/surveys`);
  }

  findByTitle(title) {
    return http.get(`/surveys?title=${title}`);
  }

  createAnswer(data) {
    return http.post("/survey-results/", data);
  }

  getAnswer(uuid) {
    return http.get(`/slugify/slugs/survey-result/${uuid}`);
  }

  /*
   * Calendario / Eventos / Agenda
   */

  createEvent(data) {
    return http.post("/calendars", data);
  }
}

export default new wsSurveys();
