<template>
  <div>
    <ResponsiveComponent
      cfgImage="https://api-vinculacion.k0s.ime.red/uploads/concurso_de_literatura_2023_PORTADA_WEB_866e1cbd5c.jpg"
      cfgHeight="42vh"
    ></ResponsiveComponent>
    <!-- :cfgImage="path.storage_files + currentSurvey.cover.data.attributes.url" -->

    <v-container>
      <Survey class="mt-10 px-8 py-6" :survey="survey" />

      <v-btn
        v-if="currentAnswer.uuid"
        @click="generateReport()"
        x-large
        dark
        color="rojo"
      >
        <v-icon left>mdi-file-pdf-box</v-icon>
        Descargar Comprobante
      </v-btn>

      <div v-if="currentAnswer.uuid">
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          :filename="currentAnswer.uuid"
          :pdf-quality="2"
          :enableLinks="true"
          :manual-pagination="false"
          pdf-format="letter"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          @hasStartedGeneration="hasStartedGeneration()"
          @hasGenerated="hasGenerated($event)"
          ref="html2Pdf"
        >
          <section slot="pdf-content" style="margin: 22pt 24pt">
            <img
              src="https://api-vinculacion.k0s.ime.red/uploads/concurso_de_literatura_2023_PORTADA_WEB_866e1cbd5c.jpg"
              style="width: 100% !important"
              alt=""
            />

            <table class="tg">
              <thead>
                <tr>
                  <th class="tg-0y03" bgcolor="#607d8b" colspan="3">
                    <small>Folio</small>
                    {{ currentAnswer.uuid }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="tg-0lax" width="33%">
                    <small>País</small>
                    {{ currentAnswer.answer.paisResidencia }}
                  </td>
                  <td class="tg-0lax" width="33%">
                    <small>Años residiendo en dicho país</small>
                    {{ currentAnswer.answer.aniosRecidencia }}
                  </td>
                  <td class="tg-0lax" width="33%">
                    <small>Representación</small>
                    {{ currentAnswer.answer.rme }}
                  </td>
                </tr>
                <tr>
                  <td class="tg-0lax" width="33%">
                    <small>Nombre completo</small>
                    {{ currentAnswer.answer.nombreCompleto }}
                  </td>
                  <td class="tg-0lax" width="33%">
                    <small>Fecha de nacimiento</small>
                    {{ currentAnswer.answer.fechaNacimiento }}
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td class="tg-0lax">
                    <small>Estado de origen en México</small>
                    {{ currentAnswer.answer.estadoOrigen }}
                  </td>
                  <td class="tg-0lax">
                    <small>Correo electrónico: </small>
                    {{ currentAnswer.answer.email }}
                  </td>
                  <td class="tg-0lax">
                    <small>telefono: </small>
                    {{ currentAnswer.answer.telefono }}
                  </td>
                </tr>
                <tr>
                  <td class="tg-0lax" colspan="3">
                    <small>Dirección completa</small>
                    {{ currentAnswer.answer.address }}
                  </td>
                </tr>
                <tr>
                  <td class="tg-0lax">
                    <small>Temática de la obra: </small>
                    {{ currentAnswer.answer.tematicaObra }}
                  </td>
                  <td class="tg-0lax">
                    <small>Extensión de la obra: </small>
                    {{ currentAnswer.answer.extensionObra }}
                  </td>
                  <td class="tg-0lax"></td>
                </tr>
                <tr>
                  <td class="tg-0lax" colspan="3">
                    <small>Titulo de la Obra:</small>
                    {{ currentAnswer.answer.tituloObra }}
                  </td>
                </tr>
                <tr>
                  <td class="tg-0lax" colspan="3">
                    <small
                      >Breve descripción de la obra (máximo 300
                      palabras):</small
                    >
                    {{ currentAnswer.answer.descripcionObra }}
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="html2pdf__page-break" />

            <table class="tg" style="margin-top: 18pt">
              <tbody>
                <tr>
                  <td class="tg-0lax">
                    <small>Texto literario</small>
                    <a
                      :href="currentAnswer.answer.obraLiteraria[0].content"
                      class="printable"
                    >
                      {{ currentAnswer.answer.obraLiteraria[0].content }}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <!---<small>Verificar: </small>
                    <a :href="`https://ime.gob.mx/verify/${currentAnswer.uuid}`" >
                      https://ime.gob.mx/verify/{{currentAnswer.uuid}}
                    </a>-->
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="html2pdf__page-break" />

            <table class="tg" style="margin-top: 18pt">
              <tbody>
                <tr>
                  <td class="tg-0lax">
                    <h2>
                      CARTA DE CESIÓN DE DERECHOS USO DE OBRA DEL AUTOR/AUTORA
                    </h2>
                    <br />
                    <p class="">
                      Instituto de los Mexicanos en el Exterior<br />
                      <strong>P R E S E N T E</strong>
                    </p>
                    <br /><br />
                    <p class="">
                      Yo, &nbsp;
                      <strong>{{ currentAnswer.answer.nombreCompleto }}</strong>
                      &nbsp; con identificación &nbsp;
                      <strong>{{ currentAnswer.answer.numeroFolio }}</strong>
                      &nbsp; de (la) que verifica mi nacionalidad. Expreso mi
                      libre voluntad y conformidad de ceder los derechos no
                      comerciales para el uso de difusión y promoción de la obra
                      &nbsp;
                      <strong>{{ currentAnswer.answer.tituloObra }}</strong>
                      &nbsp; que participa en el 2do Concurso de Literatura para
                      la diáspora mexicana” bajo la temática: "Tradiciones
                      Mexicanas" organizado y coordinado por el Instituto de los
                      Mexicanos en el Exterior de la Secretaría de Relaciones
                      Exteriores, la Embajada de México en Alemania y el
                      Capítulo Alemania y Baviera de la Red Global MX.
                    </p>
                    <p class="">
                      Manifiesto que el texto participante es completamente
                      original y de mi autoría, y no ha sido publicado ni
                      mostrado, por ende, es inédito.
                    </p>
                    <p class="">
                      Por lo que exento al IME, de cualquier responsabilidad, en
                      caso de falsedad de mi dicho.
                    </p>
                    <p class="">
                      Lo anterior con carácter permanente irrevocable y a título
                      gratuito. Teniendo efecto desde el momento en que se
                      reciba la carta.
                    </p>
                    <br /><br /><br /><br />
                    <center>
                      <img
                        :src="currentAnswer.answer.firmaCartaCesion"
                        alt="firmaCartaCesion"
                        width="450"
                        height="300"
                      />
                      <p>__________________________________________</p>
                      {{ currentAnswer.answer.nombreCompleto }}
                    </center>
                    <br /><br /><br />
                    <img
                      :src="
                        currentAnswer.answer.comprobanteCiudadaniaDocumento[0]
                          .content
                      "
                      style="width: 100% !important"
                      alt=""
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="html2pdf__page-break" />

            <table class="tg" style="margin-top: 18pt">
              <tbody>
                <tr>
                  <td class="tg-0lax">
                    <h2>CARTA BAJO PROTESTA DE DECIR VERDAD</h2>
                    <br />
                    <p>
                      Instituto de los Mexicanos en el Exterior<br />
                      <strong>PRESENTE</strong>
                    </p>
                    <br /><br />
                    <p>
                      Por la presente yo &nbsp;
                      <strong>
                        {{ currentAnswer.answer.nombreCompleto }}
                      </strong>
                      &nbsp; hago
                      <strong>constar bajo protesta de decir la verdad</strong>,
                      y cumpliendo con lo señalado en las bases de la
                      convocatoria del 2do Concurso de Literatura para la
                      diáspora mexicana 2023 “Tradiciones Mexicanas” que el
                      texto participante es de mi autoría y no ha sido publicado
                      anteriormente. Asimismo, en caso de plagio exento al
                      Instituto de los Mexicanos en el Exterior de la Secretaría
                      de Relaciones Exteriores a la Embajada de México en
                      Alemania y al Capítulo Alemania y Baviera de la Red Global
                      MX de cualquier responsabilidad.
                    </p>
                    <br /><br /><br /><br /><br /><br />
                    <center>
                      <img
                        :src="currentAnswer.answer.firmaCartaProtesta"
                        alt="firmaCartaProtesta"
                        width="450"
                        height="300"
                      />
                      <p>__________________________________________</p>
                      {{ currentAnswer.answer.nombreCompleto }}
                    </center>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="html2pdf__page-break" />

            <table class="tg" style="margin-top: 18pt">
              <tbody>
                <tr>
                  <td class="tg-0lax">
                    <h2>CARTA DE ACEPTACIÓN</h2>
                    <br />
                    <p class="">
                      <strong>
                        {{ currentAnswer.answer.nombreCompleto }}
                      </strong>
                    </p>
                    <br /><br />
                    <p>
                      Por medio de la presente, me permito ACEPTAR las bases de
                      la convocatoria del 2do Concurso de Literatura para la
                      diáspora mexicana 2023
                      <strong>“Tradiciones Mexicanas”</strong> coordinada por el
                      Instituto de los Mexicanos en el Exterior de la Secretaría
                      de Relaciones Exteriores, con el apoyo de la Embajada de
                      México en Alemania y del Capítulo Alemania y Baviera de la
                      Red Global MX.
                    </p>
                    <br /><br /><br /><br /><br /><br />
                    <center>
                      <img
                        :src="currentAnswer.answer.firmaCartaAceptacion"
                        alt="firmaCartaAceptacion"
                        width="450"
                        height="300"
                      />
                      <p>__________________________________________</p>
                      {{ currentAnswer.answer.nombreCompleto }}
                    </center>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </vue-html2pdf>
      </div>
    </v-container>
  </div>
</template>

<script>
import ResponsiveComponent from "@/components/global/_Responsive";

import "survey-core/defaultV2.min.css";
import "survey-core/survey.i18n";
import QrcodeVue from "qrcode.vue";
import { Base64 } from "js-base64";

import { uuid } from "vue-uuid";
import { Model } from "survey-core";
import { Survey } from "survey-vue-ui";
import wsSurveys from "@/services/surveys";
import authHeader from "@/services/auth-header";
import VueHtml2pdf from "vue-html2pdf";
import json from "@/services/forms/literatura2023_end";

export default {
  name: "Literatura2023View",

  components: {
    Survey,
    Base64,
    QrcodeVue,
    VueHtml2pdf,
    ResponsiveComponent,
  },

  data() {
    const survey = new Model(json);

    survey.locale = "es";
    //survey.locale = "en";
    survey.onComplete.add(this.saveAnswer);

    survey.onClearFiles.add(function (survey, options) {
      options.callback("success");
    });

    survey.onUploadFiles.add(function (survey, options) {
      options.files.forEach((file) => {
        const formData = new FormData();
        formData.append("files", file, "CLDM2023_" + file.name);
        console.log("🚀 ~ file: SurveyView.vue:46 ~ file:", file);
        const xhr = new XMLHttpRequest();
        //xhr.responseType = "json";
        xhr.open("POST", process.env.VUE_APP_ENDPOINT + "/upload/");
        xhr.onload = () => {
          if (xhr.status === 200) {
            const data = JSON.parse(xhr.response);
            const content = data[0];
            options.callback("success", [
              {
                file: file,
                content: process.env.VUE_APP_STORAGE_FILES + content.url,
                url: content.url,
              },
            ]);
          }
        };

        xhr.setRequestHeader(
          "Authorization",
          "Bearer " + process.env.VUE_APP_TOKEN_API
        );
        xhr.send(formData, { headers: authHeader() });
      });
    });

    return {
      survey,
      uuidV4: uuid.v4(),
      codeAnswer: "",
      encodeAnswer: "",
      qrValue: "https://ime.gob.mx/verify/",
      qrSize: 300,
      currentAnswer: {},
      currentSurvey: {},
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
    };
  },

  computed: {
    uuid() {
      var result = this.$route.params.name;
      return result;
    },
    token() {
      return process.env.VUE_APP_TOKEN_API;
    },
  },

  mounted() {
    document.title =
      "Concurso de Literatura para la diáspora mexicana 2023 | Vinculación y Cultura | Instituto de los Mexicanos en el Exterior";
    //this.getSurvey(this.uuid)
  },

  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },

    saveAnswer(sender) {
      //const answer = JSON.stringify(sender.data);
      const answer = sender.data;
      var data = {
        uuid: this.uuidV4,
        answer: answer,
        fullName: answer.nombreCompleto,
        rmeName: answer.rme,
        survey: 2, //this.surveyID
      };

      wsSurveys.createAnswer({ data: data }).then(
        (response) => {
          this.currentAnswer = response.data.data;

          this.codeAnswer =
            "||IME|CDL2023|" +
            this.currentAnswer.answer.paisResidencia +
            "|" +
            this.currentAnswer.answer.rme +
            "|" +
            this.currentAnswer.answer.nombreCompleto +
            "|" +
            this.currentAnswer.answer.tematicaObra +
            "||";
          console.log(
            "🚀 ~ file: SurveyView.vue:376 ~ saveAnswer ~ this.codeAnswer:",
            this.codeAnswer
          );

          this.encodeAnswer = Base64.encode(this.codeAnswer);
          console.log(
            "🚀 ~ file: SurveyView.vue:380 ~ saveAnswer ~ this.encodeAnwer:",
            this.encodeAnwer
          );
        },
        (error) => {
          this.currentAnswer =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    getSurvey(name) {
      wsSurveys
        .get(name)
        .then((response) => {
          this.currentSurvey = response.data.data;
          this.jsonFormSurvey = response.data.data.form;
          this.surveyID = response.data.data.id;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style>
table {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.tg {
  border-collapse: collapse;
  border-spacing: 0;
}
.tg td {
  border-color: #cfd8dc;
  border-style: solid;
  border-width: 1px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}
.tg th {
  border-color: #cfd8dc;
  border-style: solid;
  border-width: 1px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}
.tg .tg-6zu0 {
  background-color: #263238;
  color: #ffffff;
  text-align: left;
  vertical-align: top;
}
.tg .tg-0y03 {
  background-color: #263238;
  border-color: inherit;
  color: #ffffff;
  text-align: left;
  vertical-align: top;
}
.tg .tg-0pky {
  border-color: inherit;
  text-align: left;
  vertical-align: top;
}
.tg .tg-0lax {
  text-align: left;
  vertical-align: top;
}
small {
  display: block;
  text-transform: uppercase;
  color: #607d8b;
}
a.printable:link:after,
a.printable:visited:after {
  content: " [" attr(href) "] ";
}
</style>
