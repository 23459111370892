import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import VueMoment from "vue-moment";
import moment from "moment-timezone";
require("moment/locale/es-mx");

import VueSessionStorage from "vue-sessionstorage";
import "@/assets/css/styles.css";
import "@/assets/css/gobmx.css";
import "@/assets/css/tables.css";
//import 'survey-core/defaultV2.min.css';

Vue.config.productionTip = false;
Vue.use(VueSessionStorage);
Vue.use(VueMoment, {
  moment,
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
