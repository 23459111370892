import Vue from "vue";
import VueRouter from "vue-router";

import Login from "@/views/auth/Login.vue";
import ResetPassword from "@/views/auth/ResetPassword.vue";
import EmailConfirmation from "@/views/auth/EmailConfirmation.vue";
import Register from "@/views/auth/Register.vue";

import SurveyList from "@/views/surveys/List.vue";
import SurveyView from "@/views/surveys/SurveyView.vue";
import ResultsListView from "@/views/results/List.vue";
import Cdi2023View from "@/views/convocatorias/dibujo-infantil-2023.vue";
import Literatura2023View from "@/views/convocatorias/concurso-de-literatura-2023.vue";

import RetoAltares2023View from "@/views/RetoAltares/Formulario.vue";
import VotacionRetoAltares2023View from "@/views/RetoAltares/VotacionBasica.vue";
import VotacionRetoAltaresRme2023View from "@/views/RetoAltares/End.vue";
import VotacionRetoAltaresComunidad2023View from "@/views/RetoAltares/EndComunidad.vue";
import VotacionTopTenComponent from "@/views/RetoAltares/TopTen.vue";
import VotacionAltarComponent from "@/views/RetoAltares/Altar.vue";

import SemanaGlobal2023View from "@/views/SemanaGlobal/Formulario.vue";
import Jba2023View from "@/views/JornadaArtistas/Formulario.vue";
import CineMigrante2023View from "@/views/CineMigrate/Formulario.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "SurveyList",
    component: SurveyList,
  },

  /* Auth */
  /*
  {
    path: "/auth/login",
    component: Login,
  },
  {
    path: "/auth/register",
    component: Register,
  },
  {
    path: "/auth/reset-password",
    name: "AuthResetPassword",
    component: ResetPassword,
  },
  {
    path: "/auth/email-confirmation/:token?",
    name: "EmailConfirmation",
    component: EmailConfirmation,
  },
*/

  /* User > Profile */
  /*
  {
    path: "/user/profile",
    name: "profile",
    component: () =>
      import( "@/views/auth/Profile.vue"),
  },
  */

  {
    path: "/form/:name",
    name: "SurveyView",
    component: SurveyView,
  },
  {
    path: "/results/",
    name: "ResultsList",
    component: ResultsListView,
  },
  {
    path: "/convocatoria/cdi2023",
    name: "Cdi2023",
    component: Cdi2023View,
  },
  {
    path: "/convocatoria/literatura2023",
    name: "Literatura2023",
    component: Literatura2023View,
  },

  {
    path: "/convocatoria/RetoDeAltares2023",
    name: "RetoAltares2023",
    component: RetoAltares2023View,
  },
  {
    path: "/votacion/RetoDeAltares2023/",
    name: "VotacionRetoAltares2023",
    component: VotacionRetoAltares2023View,
  },
  {
    path: "/votacion/topten/:category?/:range?",
    name: "VotacionTopTen",
    component: VotacionTopTenComponent,
  },
  {
    path: "/votacion/RetoDeAltares2023/representacion/:page?",
    name: "VotoRme",
    component: VotacionRetoAltaresRme2023View,
  },
  {
    path: "/votacion/RetoDeAltares2023/comunidad/:page?",
    name: "VotoComunidad",
    component: VotacionRetoAltaresComunidad2023View,
  },
  {
    path: "/votacion/altar/:id",
    name: "VotacionAltar",
    component: VotacionAltarComponent,
  },

  {
    path: "/convocatoria/SemanaGlobal2023",
    name: "SemanaGlobal2023",
    component: SemanaGlobal2023View,
  },
  {
    path: "/convocatoria/jba2023",
    name: "Jba2023",
    component: Jba2023View,
  },
  {
    path: "/convocatoria/cine-migrante",
    name: "CineMigrante2023",
    component: CineMigrante2023View,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
