<template>
  <section class="mb-5">
    <v-row>
      <v-col cols md="12" class="d-flex justify-center">
        <v-btn
          :disabled="page === 1"
          x-small
          class="ma-1"
          outlined
          fab
          :color="cfgColor"
          :href="`?page=1`"
        >
          <v-icon>mdi-page-first</v-icon>
        </v-btn>
        <v-btn
          :disabled="page === 1"
          x-small
          class="ma-1"
          outlined
          fab
          :color="cfgColor"
          :href="`?page=${page - 1}`"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="text-button mx-3">
          Página <strong>{{ page }}</strong> de
          <strong>{{ pageCount }} </strong>
        </span>

        <v-btn
          :disabled="page === pageCount"
          x-small
          class="ma-1"
          outlined
          fab
          :color="cfgColor"
          :href="`?page=${page + 1}`"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn
          :disabled="page === pageCount"
          x-small
          class="ma-1"
          outlined
          fab
          :color="cfgColor"
          :href="`?page=${pageCount}`"
        >
          <v-icon>mdi-page-last</v-icon>
        </v-btn>
      </v-col>

      <v-col cols md="12" class="text-center">
        <span class="text-button mx-3">
          Registros totales <strong>{{ total }}</strong>
        </span>
      </v-col>
    </v-row>
  </section>
</template>
<script>
export default {
  name: "PaginationComponent",
  props: {
    page: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 25,
    },
    pageCount: {
      type: Number,
      default: 25,
    },
    total: {
      type: Number,
      default: 1,
    },
    cfgColor: {
      type: String,
      default: "blue-grey darken-2",
    },
  },
};
</script>
